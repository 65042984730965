import React from 'react'

function Footer(): JSX.Element {
  return (
    <footer className='mt-auto text-center'>
      <p>
        Designed and Developed by {' '}
        <a target='_blank' rel='noopener noreferrer' href='https://fbtech.ca'>
          FBTech.ca
        </a>
      </p>
    </footer>
  )
}

export default Footer
